import React, { useEffect, useState, useMemo, createContext } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import LinearProgress from '@material-ui/core/LinearProgress'
import '../styles/globals.css'

import makeTheme, { DarkModeProvider } from '../src/helpers/Theme'
import { pageview } from '../src/helpers/TagManager'

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

let firebaseApp;

export const AuthContext = createContext(null);

const initializeFirebase = () => {
    if (!firebaseApp) {
        const firebaseConfig = {
            apiKey: "AIzaSyAyD5yIkFaTEMj95fel0DdL3jHSB6fOj9A",
            authDomain: "mls-search-system.firebaseapp.com",
            projectId: "mls-search-system",
            storageBucket: "mls-search-system.appspot.com",
            messagingSenderId: "849424157554",
            appId: "1:849424157554:web:c86dab28f018a36973ebeb",
            measurementId: "G-6VSLL1J6ZP"
        }

        firebaseApp = initializeApp(firebaseConfig);
        getAnalytics(firebaseApp);
    }
};
  
const MyApp = ({ Component, pageProps }) => {
    
    const [currentUser, setCurrentUser] = useState(null);

    
    // useEffect(async () => {

    //     initializeFirebase();
          
    //     //initializeFirebase();

    //     const auth = getAuth();

    //     // Or sign in an existing user (only for testing, don't hardcode sensitive info)
    //     signInWithEmailAndPassword(auth, "mehdialali22@gmail.com", "12345678")
    //     .then((userCredential) => {
    //       // Successfully signed in
    //       console.log('User signed in: ', userCredential);
    //     })
    //     .catch((error) => {
    //       // Error occurred
    //       console.error(error);
    //     });
    //   }, []);
    
      useEffect(() => {

        initializeFirebase();

        const auth = getAuth();
        
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            console.log(user); // This should reliably print the current user or null
        });
      
        // Cleanup subscription on unmount
        // Sets current user to null, signfying no user is logged in
        return () => unsubscribe();
      }, []);
      

    const router = useRouter()

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])

    const [dark, setDark] = useState(false)
    const toggleDark = () => setDark(!dark)

    const [loading, setLoading] = useState(false)
    const routeChangeStart = () => setLoading(true)
    const routeChangeError = () => setLoading(false)
    const routeChangeComplete = (url) => {
        setLoading(false)
        pageview(url)
    }

    useEffect(() => {
        router.events.on('routeChangeStart', routeChangeStart)
        router.events.on('routeChangeError', routeChangeError)
        router.events.on('routeChangeComplete', routeChangeComplete)

        return () => {
            router.events.off('routeChangeStart', routeChangeStart)
            router.events.off('routeChangeComplete', routeChangeComplete)
            router.events.off('routeChangeError', routeChangeError)
        }
    }, [router])

    const theme = useMemo(() => makeTheme(dark), [dark])

    return (
        <>
            <Head>
                <title>Bayshirerealty | To Invest In Real Estate With Confidence</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Head>
            <AuthContext.Provider value={{ currentUser }}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <DarkModeProvider value={{ dark, toggleDark }}>
                        <CssBaseline />
                        {loading && (
                            <div className="routing-progress">
                                <LinearProgress
                                    color={dark ? 'primary' : 'secondary'}
                                    variant="indeterminate"
                                />
                            </div>
                        )}
                        <Component {...pageProps} loading={loading} />
                    </DarkModeProvider>
                </SnackbarProvider>
            </ThemeProvider>
            </AuthContext.Provider>
        </>
    )
}

// noinspection JSUnusedGlobalSymbols
export default MyApp
