import { createContext } from 'react'
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const DarkModeContext = createContext({
    dark: false,
    toggleDark: () => {},
})
export const DarkModeProvider = DarkModeContext.Provider

const theme = (isDark = false) =>
    responsiveFontSizes(
        createTheme({
            typography: {
                fontFamily: "'Inter', serif",
                fontWeightLight: 400,
                fontWeightRegular: 500,
                fontWeightMedium: 600,
                fontWeightBold: 800,
                h1: {
                    fontFamily: "'Cormorant', serif",
                    fontSize: '49px',
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                    fontWeight: 400,
                    //fontSize: '3rem', // adjust to your needs
                },
                h2: {
                    fontSize: '49px',

                    fontFamily: 'Cormorant',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '56px',
                    color: '#003466',
                    lineHeight: 1.2,
                },
                h3: {
                    fontFamily: 'Cormorant',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '46px',
                    lineHeight: 1,
                    letterSpacing: '-0.01em',
                    color: '#FFFFFF',
                },
                h4: {
                    fontFamily: 'Cormorant',
                    fontStyle: 'normal',
                    fontSize: '38px',
                    lineHeight: 1.3,
                    letterSpacing: ' -0.03em',
                    color: '#003466',
                },
                h5: {
                    fontFamily: " 'Inter', serif",
                    fontSize: '20px',
                    fontWeight: '300',
                    textTransform: 'uppercase',
                },
                h6: {
                    lineHeight: 1.5,
                    fontFamily: " 'Inter', serif",
                    fontSize: '18px',
                },
                subtitle1: {
                    fontSize: '32px',
                    color: '#003466',
                    fontFamily: 500,
                    lineHeight: '2',
                    //fontStyle: 'italic',
                    letterSpacing: '-0.03em',
                    fontFamily: "'Cormorant', serif",
                    textTransform: 'capitalize',
                },
                subtitle2: {
                    fontSize: '16px',
                    fontWeight: '800',
                },
                caption: {
                    lineHeight: 1.5,
                    fontFamily: " 'Inter', serif",
                    fontSize: '14px',
                    fontWeight: '300',
                },
                overline: {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '18px',
                    //fontSize: '1rem', // adjust to your needs
                    lineHeight: 1,
                    textAlign: 'center',
                    letterSpacing: '0.2em',
                    textTransform: 'uppercase',
                },
                button: {
                    fontFamily: " 'Inter', serif",
                    fontSize: '16px',
                    fontWeight: 500,
                    borderRadius: '0px',
                    boxShadow: 'none !important',
                    label: {
                        textTransform: 'uppercase',
                    },
                    root: {
                        borderRadius: '0px !important',
                        paddingTop: 8,
                        paddingRight: 24,
                        paddingBottom: 8,
                        paddingLeft: 24,
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 0,
                    xxs: 150,
                    mxs: 300,
                    xsm: 450,
                    sm: 600,
                    msm: 780,
                    md: 960,
                    mmd: 1120,
                    lg: 1280,
                    mlg: 1600,
                    xl: 1920,
                },
            },
            palette: {
                type: isDark ? 'dark' : 'light',
                primary: {
                    main: '#003366',
                    // buttonColoring
                },
                secondary: {
                    main: '#003466',
                },
                grey: {
                    main: '#3A3A3A',
                    light: '#636363',
                    dark: '#EDF4FC',
                },
                background: {
                    default: isDark ? '#424242' : '#fff',
                    paper: isDark ? '#303030' : '#fff',
                },
            },
            overrides: {
                MuiButton: {
                    label: {
                        textTransform: 'none',
                    },
                    root: {
                        borderRadius: 4,
                        paddingTop: 8,
                        paddingRight: 24,
                        paddingBottom: 8,
                        paddingLeft: 24,
                    },
                },
                MuiLink: {
                    root: {
                        color: 'inherit',
                    },
                },
                MuiTypography: {
                    body1: {
                        lineHeight: 1.8,
                        fontFamily: 'Inter',
                        fontSize: '17px',
                        fontWeight: '400',
                    },
                    body2: {
                        lineHeight: '1.5em',
                        fontFamily: 'Inter',
                        fontSize: '17px',
                        fontWeight: '300',
                        letterSpacing: '0.2em',
                    },
                },
            },
        })
    )

export default theme
